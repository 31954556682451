var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "screen-capture-intro" },
    [
      _c(
        "rtb-card-body",
        [
          _c(
            "rtb-row",
            [
              _c(
                "rtb-col",
                { attrs: { xs: "4" } },
                [
                  _c("rtb-select", {
                    staticClass: "screen-capture-intro__select",
                    attrs: {
                      options: _vm.options,
                      identity: "id",
                      label: "Twitch Account",
                      "option-text": "name",
                      disabled: _vm.working || _vm.loading || _vm.streaming,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "help",
                        fn: function () {
                          return [
                            _c("rtb-inline-help", [
                              _vm._v(
                                " Select an account you want to stream to "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.selectedIDComputed,
                      callback: function ($$v) {
                        _vm.selectedIDComputed = $$v
                      },
                      expression: "selectedIDComputed",
                    },
                  }),
                ],
                1
              ),
              _vm.streaming && !!_vm.destinationUrl
                ? _c(
                    "rtb-col",
                    {
                      staticClass:
                        "d-flex align-center screen-capture-intro__url",
                      attrs: { xs: "8" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.syncing
                              ? "STREAM WILL BE AVAILABLE SOON:"
                              : "STREAMING TO:"
                          ) +
                          " "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "pl-2",
                          staticStyle: { color: "#fff" },
                          attrs: { target: "_blank", href: _vm.destinationUrl },
                        },
                        [_vm._v(_vm._s(_vm.destinationUrl))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          !!_vm.messageComputed
            ? _c(
                "rtb-row",
                [
                  _c(
                    "rtb-col",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: { xs: "12" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "screen-capture-intro__message" },
                        [_vm._v(_vm._s(_vm.messageComputed))]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("Tips"),
        ],
        1
      ),
      _c(
        "rtb-card-actions",
        [
          _c("rtb-checkbox", {
            staticClass: "mr-2",
            attrs: { label: "Debug", disabled: _vm.loading || _vm.streaming },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [
                    _c("rtb-inline-help", [_vm._v("Log server activity ")]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.debug,
              callback: function ($$v) {
                _vm.debug = $$v
              },
              expression: "debug",
            },
          }),
          _c("rtb-select", {
            staticClass: "mr-2",
            staticStyle: { width: "120px", "margin-top": "-20px" },
            attrs: {
              options: _vm.performanceOptions,
              label: "Quality",
              disabled: _vm.loading || _vm.streaming,
            },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [
                    _c("rtb-inline-help", [
                      _vm._v("Select video stream quality"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.performanceComputed,
              callback: function ($$v) {
                _vm.performanceComputed = $$v
              },
              expression: "performanceComputed",
            },
          }),
          _c(
            "rtb-button",
            {
              attrs: { color: "dark", disabled: _vm.working || _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _vm.streaming
            ? _c(
                "rtb-button",
                {
                  attrs: { disabled: _vm.working || _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.onStop()
                    },
                  },
                },
                [_vm._v(" Stop Streaming ")]
              )
            : _c(
                "rtb-button",
                {
                  attrs: {
                    disabled:
                      _vm.working || _vm.loading || !_vm.selectedIDComputed,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onStart()
                    },
                  },
                },
                [_vm._v(" Start Streaming ")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }