<template>
  <rtb-card class="screen-capture-intro">
    <rtb-card-body>
      <rtb-row>
        <rtb-col xs="4">
          <rtb-select
            v-model="selectedIDComputed"
            :options="options"
            identity="id"
            label="Twitch Account"
            option-text="name"
            :disabled="working || loading || streaming"
            class="screen-capture-intro__select"
          >
            <template #help>
              <rtb-inline-help>
                Select an account you want to stream to
              </rtb-inline-help>
            </template>
          </rtb-select>
        </rtb-col>
        <rtb-col
          xs="8"
          class="d-flex align-center screen-capture-intro__url"
          v-if="streaming && !!destinationUrl"
        >
          {{ syncing ? "STREAM WILL BE AVAILABLE SOON:" : "STREAMING TO:" }}
          <a
            class="pl-2"
            target="_blank"
            style="color: #fff"
            :href="destinationUrl"
            >{{ destinationUrl }}</a
          >
        </rtb-col>
      </rtb-row>
      <rtb-row v-if="!!messageComputed">
        <rtb-col xs="12" style="padding-bottom: 0">
          <span class="screen-capture-intro__message">{{
            messageComputed
          }}</span>
        </rtb-col>
      </rtb-row>
      <Tips />
    </rtb-card-body>
    <rtb-card-actions>
      <rtb-checkbox
        v-model="debug"
        label="Debug"
        class="mr-2"
        :disabled="loading || streaming"
        ><template #help
          ><rtb-inline-help>Log server activity </rtb-inline-help></template
        ></rtb-checkbox
      >
      <rtb-select
        :options="performanceOptions"
        v-model="performanceComputed"
        label="Quality"
        class="mr-2"
        :disabled="loading || streaming"
        style="width: 120px; margin-top: -20px"
      >
        <template #help>
          <rtb-inline-help>Select video stream quality</rtb-inline-help>
        </template>
      </rtb-select>
      <rtb-button
        color="dark"
        @click="$emit('close')"
        :disabled="working || loading"
      >
        Cancel
      </rtb-button>
      <rtb-button
        v-if="streaming"
        @click="onStop()"
        :disabled="working || loading"
      >
        Stop Streaming
      </rtb-button>
      <rtb-button
        v-else
        @click="onStart()"
        :disabled="working || loading || !selectedIDComputed"
      >
        Start Streaming
      </rtb-button>
    </rtb-card-actions>
  </rtb-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {
  RtbRow,
  RtbCol,
  RtbCard,
  RtbCardBody,
  RtbTextInput,
  RtbSelect,
  RtbCheckbox,
  RtbInlineHelp,
  RtbButton,
  RtbCardActions,
  RtbTab,
  RtbTabs
} from "@/components/ui"
import Tips from "./Tips"

import { PERFORMACE_OPTIONS } from "@/store/ScreenCapture"

import TwitchProvider from "@/providers/twitch.provider"

export default TwitchProvider.extend({
  name: "ScreenCaptureIntro",
  components: {
    RtbRow,
    RtbCol,
    RtbCard,
    RtbCardBody,
    RtbTextInput,
    RtbSelect,
    RtbCheckbox,
    RtbInlineHelp,
    RtbButton,
    RtbCardActions,
    RtbTab,
    RtbTabs,
    Tips
  },
  data() {
    return {
      selectedID: null,
      working: false,
      zoom: false,
      debug: false,
      message: null,
      performanceOptions: Object.values(PERFORMACE_OPTIONS)
    }
  },
  computed: {
    ...mapGetters(["orgID"]),
    ...mapGetters({ gameID: "actualGameID", game: "actualGame" }),
    ...mapGetters("ScreenCapture", ["streaming", "twitchID", "performance"]),
    ...mapGetters("auth", ["user"]),
    performanceComputed: {
      get() {
        return this.performance
      },
      set(value) {
        this.setPerformance(value)
      }
    },
    syncing() {
      return this.streaming && !this.game.streamUrl
    },
    messageComputed() {
      return this.options.length
        ? this.message
        : "Oops. There are no available streaming destinations."
    },
    selectedIDComputed: {
      get() {
        if (this.selectedID) return this.selectedID
        const [account] = this.options
        return account?.id
      },
      set(value) {
        this.selectedID = value
      }
    },
    destination() {
      return this.TwitchProvider_normalized.find(obj => obj.id == this.twitchID)
    },
    loading() {
      return this.TwitchProvider_loading
    },
    destinationUrl() {
      return this.destination?.url
    },
    options() {
      return this.TwitchProvider_normalized.filter(
        account => !account.inuse || account.id === this.twitchID
      )
    }
  },
  methods: {
    ...mapActions("ScreenCapture", [
      "startStreaming",
      "stopStreaming",
      "setPerformance"
    ]),
    onStart() {
      this.start()
    },
    onStop() {
      this.stop()
    },
    async start() {
      try {
        this.message = null
        this.working = true

        const data = this.TwitchProvider_raw?.[this.selectedIDComputed] || {}
        const { apiKey: twitchKey, url: streamUrl } = data
        const uuid = streamUrl.substr(streamUrl.lastIndexOf("/") + 1)

        const twitchID = this.selectedIDComputed
        const gameID = this.gameID

        // if (!twitchKey) throw new Error("Undefined Twitch API key")
        if (!twitchID) throw new Error("Undefined Twitch ID")
        if (!streamUrl) throw new Error("Undefined Twitch stream URL")
        if (!gameID) throw new Error("Undefined game ID")

        await this.startStreaming({
          twitchKey,
          debug: this.debug,
          performance: this.performance,
          twitchID,
          gameID,
          streamUrl,
          uuid
        })
      } catch (e) {
        this.message = e.message
        this.working = false
        return
      }

      this.working = false
      this.$emit("close")
    },
    async stop() {
      this.working = true
      try {
        await this.stopStreaming()
      } catch (e) {
        console.error(e)
        this.message = e.message
        this.working = false
        return
      }
      this.working = false
      this.$emit("close")
    }
  }
})
</script>

<style lang="scss">
.screen-capture-intro {
  &__url {
    line-height: 1;
    margin-top: 16px;
    color: #fff;
  }
  &__select {
    position: relative;
    z-index: 3;
  }
  &__message {
    display: inline-block;
    padding: 2px 10px;
    font-weight: bold;
    background-color: #fff;
    border-radius: 3px;
    color: #ff5252;
  }
}
</style>
