<template>
  <rtb-row class="mt-2">
    <rtb-col xs="12" class="screen-capture-intro-tips__steps">
      <img
        class="screen-capture-intro-tips__steps-image"
        :class="zoom ? '--with-zoom' : ''"
        :src="require('@/assets/screen-capture-steps.jpeg')"
      />
      <div class="screen-capture-intro-tips__tips">
        <h3>TIPS</h3>
        <slot>
          <ul>
            <li>The stream can terminate due to inactivity</li>
            <li>Don't forget to share audio</li>
            <li>Try "LOW QUALITY" setting for better performance</li>
            <li>Ensure you select the correct browser tab to stream from</li>
          </ul>
        </slot>
      </div>
      <v-icon
        class="screen-capture-intro-tips__zoom-icon"
        v-if="!zoom"
        @click="zoom = true"
      >
        zoom_in
      </v-icon>
      <v-icon
        class="screen-capture-intro-tips__zoom-icon"
        v-else
        @click="zoom = false"
      >
        zoom_out
      </v-icon>
    </rtb-col>
  </rtb-row>
</template>

<script>
import { RtbRow, RtbCol } from "@/components/ui"

export default {
  name: "ScreenCaptureTips",
  components: {
    RtbRow,
    RtbCol
  },
  data() {
    return {
      zoom: false
    }
  }
}
</script>

<style lang="scss">
.screen-capture-intro-tips {
  &__steps {
    position: relative;
  }
  &__steps-image {
    max-width: 25%;
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    &.--with-zoom {
      margin-right: 0px;
      max-width: 100%;
      max-height: 50vh;
    }
  }
  &__tips {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
  }
  &__zoom-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 46px;
    opacity: 0.8;
    color: $primary_accent_color !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    &:hover {
      opacity: 1;
    }
  }
}
</style>
