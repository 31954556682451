var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-row",
    { staticClass: "mt-2" },
    [
      _c(
        "rtb-col",
        {
          staticClass: "screen-capture-intro-tips__steps",
          attrs: { xs: "12" },
        },
        [
          _c("img", {
            staticClass: "screen-capture-intro-tips__steps-image",
            class: _vm.zoom ? "--with-zoom" : "",
            attrs: { src: require("@/assets/screen-capture-steps.jpeg") },
          }),
          _c(
            "div",
            { staticClass: "screen-capture-intro-tips__tips" },
            [
              _c("h3", [_vm._v("TIPS")]),
              _vm._t("default", function () {
                return [
                  _c("ul", [
                    _c("li", [
                      _vm._v("The stream can terminate due to inactivity"),
                    ]),
                    _c("li", [_vm._v("Don't forget to share audio")]),
                    _c("li", [
                      _vm._v(
                        'Try "LOW QUALITY" setting for better performance'
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "Ensure you select the correct browser tab to stream from"
                      ),
                    ]),
                  ]),
                ]
              }),
            ],
            2
          ),
          !_vm.zoom
            ? _c(
                "v-icon",
                {
                  staticClass: "screen-capture-intro-tips__zoom-icon",
                  on: {
                    click: function ($event) {
                      _vm.zoom = true
                    },
                  },
                },
                [_vm._v(" zoom_in ")]
              )
            : _c(
                "v-icon",
                {
                  staticClass: "screen-capture-intro-tips__zoom-icon",
                  on: {
                    click: function ($event) {
                      _vm.zoom = false
                    },
                  },
                },
                [_vm._v(" zoom_out ")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }