import Vue, { VNode } from "vue"
import { db } from "@/firebase"

import { mapGetters } from "vuex"

const TwitchProvider = Vue.extend({
  name: "TwitchProvider",
  data() {
    return {
      TwitchProvider_loading: false,
      TwitchProvider_raw: {}
    }
  },
  computed: {
    ...mapGetters(["orgID"]),
    TwitchProvider_normalized(): Array<Object> {
      return Object.keys(this.TwitchProvider_raw).map(key => ({
        ...this.TwitchProvider_raw[key],
        id: key
      }))
    },
    ref(): Object {
      return db.ref(`orgs/${this.orgID}/twitchAccounts`)
    }
  },
  watch: {
    ref: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.update)
        }
        if (newValue) {
          newValue.on("value", this.update)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.ref.off("value", this.update)
  },
  methods: {
    update(snapshot) {
      this.TwitchProvider_raw = snapshot.val() || {}
    }
  },
  render(): VNode {
    return this.$scopedSlots.default({
      loading: this.TwitchProvider_loading,
      options: this.TwitchProvider_normalized
    })
  }
})

export default TwitchProvider
